import { createRouter, createWebHashHistory } from 'vue-router';
const uploadFilePage = () => import('~/views/aunt/uploadFile');
const parseTextPage = () => import('~/views/aunt/parseText');
const generateArticle = () => import('~/views/aunt/generateArticle');
const articleTemplateList = () => import('~/views/articleTemplate/list');
const contractCreate = () => import('~/views/contract/create');
const contractSign = () => import('~/views/contract/sign');
const contractInfo = () => import('~/views/contract/info');
const contractList = () => import('~/views/contract/list');
const contractTemplateList = () => import('~/views/contractTemplate/list');
const contractTest = () => import('~/views/contract/test');

const routes = [
  { path: '/', redirect: '/uploadFilePage' },
  {
    path: '/uploadFilePage',
    name: 'uploadFilePage',
    component: uploadFilePage,
    meta: {
      title: '文件解析简历',
      keepAlive: false,
    },
  },
  {
    path: '/parseTextPage',
    name: 'parseTextPage',
    component: parseTextPage,
    meta: {
      title: '文本解析简历',
      keepAlive: false,
    },
  },
  {
    path: '/generateArticle',
    name: 'generateArticle',
    component: generateArticle,
    meta: {
      title: '生成图文',
      keepAlive: false,
    },
  },
  {
    path: '/contractCreate',
    name: 'contractCreate',
    component: contractCreate,
    meta: {
      title: '生成协议',
      keepAlive: true,
    },
  },
  {
    path: '/contractSign',
    name: 'contractSign',
    component: contractSign,
    meta: {
      title: '签署协议',
      keepAlive: false,
    },
  },
  {
    path: '/contractInfo',
    name: 'contractInfo',
    component: contractInfo,
    meta: {
      title: '查看协议',
      keepAlive: false,
    },
  },
  {
    path: '/contractList',
    name: 'contractList',
    component: contractList,
    meta: {
      title: '查看协议',
      keepAlive: false,
    },
  },
  {
    path: '/contractTemplateList',
    name: 'contractTemplateList',
    component: contractTemplateList,
    meta: {
      title: '查看协议模板',
      keepAlive: false,
    },
  },
  {
    path: '/contractTest',
    name: 'contractTest',
    component: contractTest,
    meta: {
      title: '合同测试',
      keepAlive: false,
    },
  },
  {
    path: '/articleTemplateList',
    name: 'articleTemplateList',
    component: articleTemplateList,
    meta: {
      title: '图文模板',
      keepAlive: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
